import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import COLORS from 'constants/colors';
import Head from 'components/head';
import { SimpleLink } from 'components/ctabutton/ctabutton.css';
import SectionMain from 'components/section-main';
import { AsideContacts } from 'components/section-main/aside.css';
import SectionMainHead from 'components/section-main-head';
import Title from 'components/title';
import PatternCircual from 'images/pattern-circual.svg';
import PatternCircle from 'images/pattern-twocircle.svg';
import Icon from 'components/icon';

const Contatti = ({ data }) => {
  const PAGE = data.contattiJson;
  const INFO = data.contattiJson.section_info;
  const OLTRE = data.contattiJson.section_oltre;

  return (
    <>
      <Head pageTitle={PAGE.title} />

      <SectionMainHead data={PAGE} />

      <SectionMain>
        <Title as="span" color="blue" size="lowercase">
          {INFO.pretext_info}
        </Title>
        <Title as="span" size="extrasmall" color="blue">
          {INFO.text_info}
        </Title>

        <Title as="span" color="blue" size="lowercase">
          {INFO.pretext_location}
        </Title>
        <Title as="span" size="extrasmall" color="blue">
          {INFO.text_location}
        </Title>

        <SimpleLink
          as="a"
          href="https://goo.gl/maps/9wiJpQUHo1YQNmvE9"
          target="_blank"
          rel="noopener noreferrer"
          color={COLORS.darkGreen}
        >
          {INFO.cta}
        </SimpleLink>

        <AsideContacts className="offsetLeft offsetRight">
          <PatternCircual fill={COLORS.lightBlue} />
          <PatternCircle />
          <Img fluid={INFO.image.childImageSharp.fluid} />
        </AsideContacts>
      </SectionMain>

      <SectionMain>
        <Title as="h2" color="pink" size="small">
          {OLTRE.title}
        </Title>

        <div
          className="offsetRight"
          dangerouslySetInnerHTML={{
            __html: OLTRE.text,
          }}
        />

        <SimpleLink
          as="a"
          href="https://www.facebook.com/oltredanzame/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="socialFb" className="icon" />
          <Title as="span" size="extrasmall">
            {OLTRE.link1}
          </Title>
        </SimpleLink>

        <SimpleLink
          as="a"
          href="https://www.instagram.com/oltredanza_official/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="socialIg" className="icon" />
          <Title as="span" size="extrasmall">
            {OLTRE.link2}
          </Title>
        </SimpleLink>

        <AsideContacts className="offsetLeft offsetRight" />
      </SectionMain>
    </>
  );
};

Contatti.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contatti;

export const query = graphql`
  query ContattiQuery {
    contattiJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      section_info {
        pretext_info
        text_info
        pretext_location
        text_location
        cta
        image {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      section_oltre {
        title
        text
        link1
        link2
      }
    }
  }
`;
